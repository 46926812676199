import { Answer } from '../types';

export const fillWithAnswers = (takenAnswers: Answer[], userChosenAnswer: Answer[]) => {
  return takenAnswers.map((answer: any) => {
    const foundAnswer = userChosenAnswer.filter(Boolean).find(el => el.questionId === answer.questionId);
    return foundAnswer || answer;
  });
};

export const fillEmptyCodeAnswers = (prevCameAnswers: any, activeQuestion: number) => {
  const prevCameAnswerCopy = [...prevCameAnswers];
  const { code } = prevCameAnswerCopy[activeQuestion];
  prevCameAnswerCopy[activeQuestion].code = {
    ...code,
    message: code?.message.trim().length ? code?.message : 'No answer is provided',
  };

  return prevCameAnswerCopy;
};
